<template>
  <div class="p-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-6">Workplace and Career Competency Scenarios</h1>

    <!-- Button to go to Dashboard if the user is logged in -->
    <button
      v-if="isLoggedIn"
      @click="goToDashboard"
      class="mb-6 px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white font-semibold rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out"
    >
      Go to Dashboard
    </button>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      <ScenarioCard
        v-for="scenario in visibleScenarios"
        :key="scenario.id"
        :title="scenario.title"
        :description="scenario.description"
        :image="scenario.image"
        :scenarioId="scenario.id"
        :isLiked="likedScenarios.includes(scenario.id)" 
        @click="handleScenarioClick(scenario.id)"
        @showSignUpModal="showSignUpModal"
      />
    </div>

    <!-- More Button -->
    <div v-if="showMoreButton" class="mt-6 flex justify-center">
      <button
        @click="loadMoreScenarios"
        class="px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white font-semibold rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out"
      >
        View More
      </button>
    </div>

    <!-- Modal for signup when user is not logged in -->
    <AuthModal
      v-if="showSignUpModalVisible"
      :type="modalType"
      @close="handleModalClose"
      @update:type="updateModalType"
    />
  </div>
</template>

<script>
import ScenarioCard from './ScenarioCard.vue';
import { scenarios } from '../scenarios.js';
import { ref, onMounted, computed } from 'vue';
import { supabase } from '../supabase';
import { useRouter } from 'vue-router';
import AuthModal from './AuthModal.vue';

export default {
  components: {
    ScenarioCard,
    AuthModal,
  },
  setup() {
    const visibleScenarios = ref([]);
    const likedScenarios = ref([]);
    const itemsPerPage = 12;
    const currentPage = ref(1);
    const isLoggedIn = ref(false);
    const showSignUpModalVisible = ref(false);
    const modalType = ref('signup');
    const scenarioViews = ref(parseInt(localStorage.getItem('scenarioViews') || '0'));
    const maxScenarioViews = 3;
    const router = useRouter();

    const checkUserStatus = async () => {
      const { data } = await supabase.auth.getUser();
      isLoggedIn.value = !!data.user;
      if (isLoggedIn.value) {
        await fetchLikedScenarios();
      }
    };

    const fetchLikedScenarios = async () => {
      const { data: user } = await supabase.auth.getUser();
      const { data: likedData, error } = await supabase
        .from('liked_scenarios')
        .select('scenario_id')
        .eq('auth_user_id', user?.id);

      if (error) {
        console.error('Error fetching liked scenarios:', error);
        return;
      }

      likedScenarios.value = likedData.map((like) => like.scenario_id);
    };

    const loadMoreScenarios = () => {
      const startIndex = (currentPage.value - 1) * itemsPerPage;
      const endIndex = currentPage.value * itemsPerPage;
      visibleScenarios.value.push(...scenarios.slice(startIndex, endIndex));
      currentPage.value += 1;
    };

    const handleScenarioClick = (id) => {
      if (!isLoggedIn.value && scenarioViews.value >= maxScenarioViews) {
        router.push({ name: 'BecomeMember' });
      } else {
        scenarioViews.value += 1;
        localStorage.setItem('scenarioViews', scenarioViews.value.toString());
        goToScenario(id);
      }
    };

    const goToScenario = (id) => {
      router.push({ name: 'ScenarioDetail', params: { id } });
    };

    const goToDashboard = () => {
      router.push({ name: 'DashboardView' });
    };

    const showSignUpModal = () => {
      showSignUpModalVisible.value = true;
    };

    const handleModalClose = () => {
      showSignUpModalVisible.value = false;
      checkUserStatus();
    };

    const updateModalType = (newType) => {
      modalType.value = newType;
    };

    onMounted(() => {
      loadMoreScenarios();
      checkUserStatus();

      supabase.auth.onAuthStateChange((event, session) => {
        isLoggedIn.value = !!session?.user;
        if (isLoggedIn.value) {
          scenarioViews.value = 0;
          localStorage.setItem('scenarioViews', '0');
        }
      });
    });

    return {
      visibleScenarios,
      likedScenarios,
      loadMoreScenarios,
      handleScenarioClick,
      isLoggedIn,
      goToDashboard,
      showMoreButton: computed(() => visibleScenarios.value.length < scenarios.length),
      showSignUpModal,
      showSignUpModalVisible,
      modalType,
      handleModalClose,
      updateModalType,
    };
  },
};
</script>
