<template>
  <div
    class="bg-white border-4 border-border shadow-brutal p-4 rounded-lg transition transform hover:scale-105 cursor-pointer relative"
    @click="$emit('click')"
  >
    <img :src="image" alt="Scenario Image" class="w-full h-32 object-cover mb-4" />
    <h3 class="text-2xl font-bold text-gray-900">{{ title }}</h3>
    <p class="text-base text-gray-700 mt-2">{{ description }}</p>

    <!-- Heart Icon Button -->
    <button
      class="absolute top-2 right-2"
      @click.stop="toggleLike"
      aria-label="Like"
    >
      <svg
        :class="{ 'text-red-500': isLiked, 'text-gray-400': !isLiked }"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41 1 4.5 2.09C13.09 4 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { supabase } from '../supabase';

export default {
  props: {
    title: String,
    description: String,
    image: String,
    scenarioId: Number,
    isLiked: Boolean,
  },
  setup(props, { emit }) {
    const user = ref(null);

    // Fetch the current user
    const fetchUser = async () => {
      const { data } = await supabase.auth.getUser();
      user.value = data.user;
    };

    // Toggle like status of the scenario
    const toggleLike = async () => {
      if (!user.value) {
        emit('showSignUpModal');
        return;
      }

      if (!props.scenarioId) {
        console.error('Scenario ID is missing or invalid.');
        return;
      }

      try {
        if (props.isLiked) {
          const { error } = await supabase
            .from('liked_scenarios')
            .delete()
            .eq('auth_user_id', user.value.id)
            .eq('scenario_id', props.scenarioId);

          if (error) throw error;

          emit('toggle-like', props.scenarioId); // Emit custom event to parent
        } else {
          const { error } = await supabase
            .from('liked_scenarios')
            .insert([{ auth_user_id: user.value.id, scenario_id: props.scenarioId }]);

          if (error) throw error;

          emit('toggle-like', props.scenarioId); // Emit custom event to parent
        }
      } catch (error) {
        console.error('Error toggling like:', error);
        alert('An error occurred. Please try again.');
      }
    };

    fetchUser();

    return {
      toggleLike,
    };
  },
};
</script>
