import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../components/HomeView.vue'; 
import ScenarioDetail from '../components/ScenarioDetail.vue';
import SecondaryComponent from '../components/SecondaryComponent.vue';
import FeedbackComponent from '../components/FeedbackComponent.vue';
import DashboardView from '../components/DashboardView.vue';
import { supabase } from '../supabase'; 
import LandingPage from '@/components/LandingPage.vue';
import BecomeMember from '@/components/BecomeMember.vue'; 
import InvestorDeck from '@/components/InvestorDeck.vue';
import SkillsAssessment from '@/components/SkillsAssessment.vue';
import LevelUpLabs from '../components/LevelUpLabs.vue'; 
import BlogView from '@/components/BlogView.vue';
import BlogDetailView from '../components/BlogDetailView.vue'; 
import AboutUs from '@/components/AboutUs.vue';
import ContactUs from '@/components/ContactUs.vue';
import SuccessView from '@/components/SuccessView.vue';
import CancelView from '@/components/CancelView.vue';

const routes = [
  // Ensure LandingPage is the default
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage, 
  },
  {
    path: '/Home',
    name: 'HomeView',
    component: HomeView, 
  },
  {
    path: '/scenarios',
    name: 'Scenarios',
    component: SecondaryComponent,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: FeedbackComponent,
  },
  {
    path: '/scenario/:id',
    name: 'ScenarioDetail',
    component: ScenarioDetail,
    props: true, 
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView,
  },
  {
    path: '/become-member',
    name: 'BecomeMember', 
    component: BecomeMember,
  },
  {
    path: '/investor-deck',
    name: 'InvestorDeck',
    component: InvestorDeck,
  },
  {
    path: '/skills-assessment',
    name: 'SkillsAssessment',
    component: SkillsAssessment,
  },
  {
    path: '/labs',
    name: 'LevelUpLabs',
    component: LevelUpLabs,
  },
  {
    path: '/blog',
    name: 'BlogView',
    component: BlogView,
  },
  { 
    path: '/blog/:id', 
    name: 'BlogDetails', 
    component: BlogDetailView 
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs, 
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,  
  },
  {
    path: '/success',
    name: 'Success',
    component: SuccessView,
  },
  {
    path: '/cancel',
    name: 'Cancel',
    component: CancelView,
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { data } = await supabase.auth.getUser();
  const user = data.user;

  if (!user && to.name === 'DashboardView') {
    next({ name: 'LandingPage' }); // Adjust this to redirect to LandingPage (Welcome) if not logged in
  } else if (user && (to.name === 'Login' || to.name === 'SignUp')) {
    next({ name: 'DashboardView' });
  } else {
    next();
  }
});

export default router;
