<template>
  <div class="landing-page-container flex flex-col items-center">
    <!-- Header Section -->
    <header class="w-full py-4 flex justify-between items-center px-4 bg-white shadow-brutal border-4 border-black">
      <h1 class="text-2xl md:text-3xl font-bold text-black">Thryve</h1>
      <button
        @click="goToHome"
        class="get-started-button text-black px-3 py-2 md:px-4 md:py-2 rounded-lg"
      >
        Get Started
      </button>
    </header>

    <!-- Hero Section -->
    <section class="hero-section flex flex-col items-center justify-center text-center px-4 mt-4">
      <h2 class="text-3xl md:text-4xl font-extrabold mb-4 text-black bg-yellow-200 inline-block p-2 border-4 border-black shadow-brutal">
        Master Your Career with Confidence
      </h2>
      <p class="text-sm md:text-base mb-4 max-w-xs md:max-w-md bg-white p-3 border-4 border-black shadow-brutal">
        Thryve provides interactive scenarios to enhance your workplace skills and navigate career challenges.
      </p>

      <!-- Subscribe Form -->
      <form @submit.prevent="subscribe" class="flex flex-col md:flex-row items-center bg-white p-3 border-4 border-black shadow-brutal">
        <input
          v-model="email"
          type="email"
          placeholder="Enter your email"
          class="input-field mb-3 md:mb-0 md:mr-3 px-2 py-1 md:px-3 md:py-2 rounded-lg text-black border-2 border-black shadow-brutal focus:outline-none focus:ring-2 focus:ring-pink-500"
          required
        />
        <button
          type="submit"
          class="subscribe-button bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white px-4 py-2 rounded-lg shadow-brutal border-2 border-black hover:bg-gray-200 transition duration-200 transform hover:scale-105"
        >
          Subscribe
        </button>
      </form>

      <p v-if="message" class="mt-4 text-sm bg-white p-2 border-2 border-black shadow-brutal">{{ message }}</p>

      <!-- Carousel Component -->
      <CarouselView class="mt-6 md:mt-8" />
    </section>

    <!-- Skills Assessment Button -->
    <button
      @click="goToSkillsAssessment"
      class="animated-button bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white px-6 py-3 rounded-lg shadow-brutal border-2 border-black hover:bg-gray-200 transition duration-200 transform hover:scale-110 animate-bounce mb-4"
    >
      Take the Skills Assessment
    </button>

    <!-- Features Section -->
    <section class="features-section grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-6 md:mt-10 px-4">
      <div class="feature-card bg-white p-4 md:p-6 border-4 border-black shadow-brutal text-center">
        <h3 class="text-lg md:text-xl font-bold mb-2">Be Prepared for the Future of Work</h3>
        <p class="text-xs md:text-sm">The future of work is unpredictable. Thryve helps you adapt by mastering skills that matter most in today’s workplace.</p>
      </div>
      <div class="feature-card bg-white p-4 md:p-6 border-4 border-black shadow-brutal text-center">
        <h3 class="text-lg md:text-xl font-bold mb-2">Why Choose Thryve?</h3>
        <p class="text-xs md:text-sm">Thryve provides real-world scenarios that sharpen your skills and make you stand out in your team.</p>
      </div>
      <div class="feature-card bg-white p-4 md:p-6 border-4 border-black shadow-brutal text-center">
        <h3 class="text-lg md:text-xl font-bold mb-2">Invest in Yourself</h3>
        <p class="text-xs md:text-sm">Thryve’s interactive scenarios make upskilling engaging, relevant, and directly applicable to your daily work challenges.</p>
      </div>
    </section>
 
    <!-- Footer Section -->
    <footer class="w-full mt-8 bg-white border-4 border-black shadow-brutal p-6 text-center">
      <!-- <p class="text-lg font-bold">Stay Connected with Us!</p> -->
      <div class="flex justify-center mt-4">
        <!-- <router-link to="/blog" class="text-blue-600 underline mx-4 hover:text-blue-400">Blog</router-link>
        <a href="https://www.twitter.com" target="_blank" class="text-blue-600 underline mx-4 hover:text-blue-400">Twitter</a>
        <a href="https://www.linkedin.com" target="_blank" class="text-blue-600 underline mx-4 hover:text-blue-400">LinkedIn</a> -->
      </div>
      <p class="text-sm mt-4">© 2024 Thryve. All Rights Reserved.</p>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { supabase } from '../supabase';
import { useRouter } from 'vue-router';
import CarouselView from './CarouselView.vue';

export default {
  components: {
    CarouselView,
  },
  setup() {
    const email = ref('');
    const message = ref('');
    const toast = useToast();
    const router = useRouter();

    const subscribe = async () => {
      if (!email.value) {
        toast.error('Please enter a valid email address.');
        return;
      }

      try {
        const { error } = await supabase.from('subscriptions').insert([{ email: email.value }]);
        if (error) throw error;

        toast.success('Thank you for subscribing!');
        email.value = '';
        message.value = 'You have successfully subscribed to updates!';
      } catch (error) {
        toast.error('Error subscribing. Please try again later.');
      }
    };

    const goToHome = () => {
      router.push({ name: 'HomeView' });
    };

    const goToSkillsAssessment = () => {
      router.push({ name: 'SkillsAssessment' });
    };

    return {
      email,
      message,
      subscribe,
      goToHome,
      goToSkillsAssessment,
    };
  },
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background: linear-gradient(to bottom, #68d391, #fc8181);
}

.landing-page-container {
  min-height: 100vh;
  width: 100%;
  padding: 0;
}

.hero-section {
  max-width: 900px;
  padding: 0 10px;
}

.shadow-brutal {
  box-shadow: 6px 6px 0px #000;
}

.animated-button {
  font-weight: bold;
  box-shadow: 3px 3px 0px #000;
  transition: all 0.3s ease-in-out;
}

.input-field {
  width: 100%;
  max-width: 260px;
}

.subscribe-button {
  font-weight: bold;
  box-shadow: 3px 3px 0px #000;
  transition: all 0.3s ease-in-out;
}

.get-started-button {
  color: #000;
  background: linear-gradient(to right, #68d391, #fc8181);
  padding: 8px 16px;
  border: 2px solid #000;
  border-radius: 8px;
  box-shadow: 3px 3px 0px #000;
  font-weight: bold;
  transition: background 0.3s, transform 0.3s;
  transform: translateY(0);
}

.get-started-button:hover {
  transform: translateY(-2px) scale(1.05);
}

.feature-card {
  max-width: 320px;
  margin: auto;
}

footer {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 6px 6px 0px #000;
}
</style>
