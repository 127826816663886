// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';

// Import Vue Toastification and its CSS
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Configure Toast options
const toastOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
};

// Create the Vue app
createApp(App)
  .use(router)
  .use(Toast, toastOptions)
  .mount('#app');
