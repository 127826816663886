<template>
  <div class="investor-deck-container p-8 bg-white border-4 border-black rounded-lg shadow-lg mt-10 max-w-5xl mx-auto">
    <h1 class="text-5xl font-extrabold text-center mb-12 text-gray-900">Thryve Investor Deck</h1>

    <!-- Password Protection -->
    <div v-if="!isAuthenticated" class="password-protect-section text-center">
      <p class="text-xl mb-6 text-gray-700">Enter the password to access the Investor Deck:</p>
      <form @submit.prevent="checkPassword" class="flex flex-col items-center">
        <input
          v-model="password"
          type="password"
          placeholder="Enter Password"
          class="input-field mb-4 px-4 py-3 border-4 border-black rounded-lg text-black shadow-lg w-full max-w-md"
          required
        />
        <button
          type="submit"
          class="access-button bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white px-8 py-3 rounded-lg shadow-lg border-4 border-black hover:bg-gray-200 transition duration-200 transform hover:scale-105"
        >
          Access Deck
        </button>
        <p v-if="errorMessage" class="mt-4 text-red-500 font-semibold">{{ errorMessage }}</p>
      </form>
    </div>

    <!-- Deck Content -->
    <div v-else class="deck-content space-y-16">
      <!-- Slide 1: Market Opportunity -->
      <div class="slide p-10 bg-white border-4 border-black rounded-lg shadow-lg">
        <h2 class="text-4xl font-bold mb-4 text-center">Market Opportunity</h2>
        <p class="text-lg text-center text-gray-700">
          The global e-learning market is projected to reach
          <span class="text-5xl font-extrabold text-red-500">$400 billion</span> by 2026, driven by the increased demand for flexible and effective learning solutions.
        </p>
      </div>

      <!-- Slide 2: The Skills Gap -->
      <div class="slide p-10 bg-white border-4 border-black rounded-lg shadow-lg">
        <h2 class="text-4xl font-bold mb-4 text-center">The Skills Gap</h2>
        <p class="text-lg text-center text-gray-700">
          Over <span class="text-5xl font-extrabold text-green-500">1 billion jobs</span> will require upskilling and reskilling by 2030.
          Traditional training methods are no longer sufficient to keep up with the evolving needs of the modern workforce.
        </p>
      </div>

      <!-- Slide 3: Our Solution -->
      <div class="slide p-10 bg-white border-4 border-black rounded-lg shadow-lg">
        <h2 class="text-4xl font-bold mb-4 text-center">Our Solution</h2>
        <p class="text-lg text-center text-gray-700">
          Thryve is designed to train and upskill individuals through interactive scenarios that simulate real workplace challenges. 
          Initially, we focus on helping people develop essential skills and build confidence.
        </p>
        <p class="text-lg text-center mt-6 text-gray-700">
          As the platform evolves, we will transition to support the future of work by moving away from the traditional 9-5 model, helping users find the right opportunities, and matching employers with the best talent.
        </p>
      </div>

      <!-- Slide 4: Market Size & Growth -->
      <div class="slide p-10 bg-white border-4 border-black rounded-lg shadow-lg">
        <h2 class="text-4xl font-bold mb-4 text-center">Market Size & Growth</h2>
        <p class="text-lg text-center text-gray-700">
          The corporate training market is valued at <span class="text-5xl font-extrabold text-blue-500">$370 billion</span> annually.
          The rapid growth is fueled by the demand for adaptable and scalable learning solutions that meet the needs of the future workforce.
        </p>
      </div>

      <!-- Slide 5: Strategic Plan & Visualization -->
      <div class="slide p-10 bg-white border-4 border-black rounded-lg shadow-lg">
        <h2 class="text-4xl font-bold mb-4 text-center">Strategic Plan & Growth Visualization</h2>
        <div class="text-lg text-gray-700">
          <p class="text-center mb-6">
            The visualization below represents Thryve's strategic growth plan from initial market penetration to sustainable expansion. Each phase marks a milestone in user acquisition, product development, and revenue growth:
          </p>
          <!-- Add Your Visualization Image Here (Replace `src` with your actual image URL) -->
          <img src="../assets/strategicplanandgrowth.png" alt="Thryve Strategic Plan Visualization" class="mx-auto">
        </div>
      </div>

      <!-- Slide 6: Pre-seed Round & Use of Funds -->
      <div class="slide p-10 bg-white border-4 border-black rounded-lg shadow-lg">
        <h2 class="text-4xl font-bold mb-4 text-center">Pre-seed Round & Use of Funds</h2>
        <p class="text-lg text-center text-gray-700">
          We are raising <span class="text-5xl font-extrabold text-indigo-600">$750,000</span> to drive growth, product development, and user acquisition.
        </p>
        <ul class="mt-6 text-lg list-disc list-inside">
          <li><strong>40%</strong> for product development & engineering</li>
          <li><strong>30%</strong> for sales & marketing</li>
          <li><strong>20%</strong> for user acquisition & partnerships</li>
          <li><strong>10%</strong> for operational expenses</li>
        </ul>
      </div>

      <!-- Slide 7: Future Vision -->
      <div class="slide p-10 bg-white border-4 border-black rounded-lg shadow-lg">
        <h2 class="text-4xl font-bold mb-4 text-center">Our Vision</h2>
        <p class="text-lg text-center text-gray-700">
          To become the leading platform for workplace skill development, where individuals can not only train and upskill but also find and complete work that aligns with their strengths and goals.
          Our vision is to redefine how the workforce learns, works, and grows by creating a dynamic ecosystem that connects skill development, job sourcing, and work fulfillment.
        </p>
      </div>

      <!-- Action Buttons -->
      <div class="flex justify-between mt-8">
        <!-- Back to Home Button -->
        <button
          @click="goBack"
          class="px-6 py-3 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white font-semibold rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out"
        >
          Go Back Home
        </button>

        <!-- Get in Touch Button -->
        <a
          href="mailto:jerome@thryveconnect.com"
          class="px-6 py-3 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white font-semibold rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out"
        >
          Get in Touch
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const password = ref('');
    const isAuthenticated = ref(false);
    const errorMessage = ref('');
    const router = useRouter();

    const correctPassword = 'password'; // Temporary password, replace with a secure solution

    const checkPassword = () => {
      if (password.value === correctPassword) {
        isAuthenticated.value = true;
        errorMessage.value = '';
      } else {
        errorMessage.value = 'Incorrect password. Please try again.';
      }
    };

    const goBack = () => {
      router.push({ name: 'LandingPage' }); // Navigate to the Welcome page
    };

    return {
      password,
      isAuthenticated,
      errorMessage,
      checkPassword,
      goBack,
    };
  },
};
</script>

<style scoped>
.investor-deck-container {
  max-width: 1200px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.slide {
  transition: all 0.3s ease-in-out;
}

.shadow-brutal {
  box-shadow: 8px 8px 0px #000;
}

.slides-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  max-height: 80vh;
  padding-bottom: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
}
</style>
