<template>
  <div class="become-member-container p-8 bg-white border-4 border-black rounded-lg shadow-lg mt-10 max-w-4xl mx-auto">
    <h1 class="text-4xl font-bold mb-6 text-gray-800">Become a Premium Member</h1>
    <p class="mb-6 text-lg text-gray-600">
      Unlock all features and access premium content with our exclusive membership plans.
    </p>

    <!-- Membership Options -->
    <div class="membership-options grid grid-cols-1 md:grid-cols-3 gap-6">
      <!-- Monthly Option -->
      <div class="option monthly-option p-6 bg-yellow-100 border-4 border-black shadow-brutal rounded-lg text-center">
        <h2 class="text-2xl font-semibold mb-2 text-indigo-600">Monthly Membership</h2>
        <p class="price text-3xl font-bold text-gray-800 mb-4">$5 <span class="text-lg font-normal">/ month</span></p>
        <button 
          @click="redirectToPayment('monthly')" 
          class="subscribe-button bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white px-4 py-2 rounded-lg shadow-lg hover:scale-105 transform transition duration-300"
        >
          Subscribe Monthly
        </button>
      </div>

      <!-- Annual Option -->
      <div class="option annual-option p-6 bg-green-100 border-4 border-black shadow-brutal rounded-lg text-center">
        <h2 class="text-2xl font-semibold mb-2 text-green-600">Annual Membership</h2>
        <p class="price text-3xl font-bold text-gray-800 mb-4">$50 <span class="text-lg font-normal">/ year</span></p>
        <button 
          @click="redirectToPayment('annual')" 
          class="subscribe-button bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white px-4 py-2 rounded-lg shadow-lg hover:scale-105 transform transition duration-300"
        >
          Subscribe Annually
        </button>
      </div>

      <!-- One-Time Promo Option -->
      <div class="option promo-option p-6 bg-blue-100 border-4 border-black shadow-brutal rounded-lg text-center">
        <h2 class="text-2xl font-semibold mb-2 text-blue-600">One-Time Promo</h2>
        <p class="price text-3xl font-bold text-gray-800 mb-4">$10 <span class="text-lg font-normal">one-time</span></p>
        <button 
          @click="redirectToPayment('promo')" 
          class="subscribe-button bg-gradient-to-r from-purple-400 to-blue-500 text-white px-4 py-2 rounded-lg shadow-lg hover:scale-105 transform transition duration-300"
        >
          Get Promo
        </button>
      </div>
    </div>

    <!-- Authentication Modal -->
    <AuthModal
      v-if="showAuthModal"
      :type="authType"
      @update:type="authType = $event"
      @close="handleAuthClose"
    />
  </div>
</template>

<script>
import AuthModal from './AuthModal.vue';
import { supabase } from '../supabase';

export default {
  name: 'BecomeMember',
  components: {
    AuthModal,
  },
  data() {
    return {
      showAuthModal: false,
      authType: 'login', // can be 'login' or 'signup'
      user: null,
    };
  },
  async created() {
    // Check if the user is already authenticated with Supabase on component load
    const { data: { session } } = await supabase.auth.getSession();
    this.user = session?.user || null;
  },
  methods: {
    async redirectToPayment(plan) {
      if (!this.user) {
        // If user is not authenticated, show the modal
        this.showAuthModal = true;
        return;
      }

      // Define Stripe Payment Links
      const paymentLinks = {
        monthly: 'https://buy.stripe.com/3cseW2dAy8CF7bW7st',
        annual: 'https://buy.stripe.com/9AQbJQ8gebOR2VGaEG',
        promo: 'https://buy.stripe.com/14k6pwgMKg572VG5kn',
      };

      // Redirect to the Stripe Payment Link
      window.location.href = paymentLinks[plan];
    },
    async handleAuthClose() {
      // Check if the user is authenticated after closing the modal
      const { data: { session } } = await supabase.auth.getSession();
      this.user = session?.user || null;
      this.showAuthModal = false;
    }
  },
};
</script>

<style scoped>
/* Add any styles you need for the component */
.become-member-container {
  max-width: 800px;
  margin: auto;
}
</style>
