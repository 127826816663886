<template>
    <div class="about-us-container p-8 bg-white border-4 border-black rounded-lg shadow-lg mt-10 max-w-4xl mx-auto">
      <!-- Header Section -->
      <div class="text-center mb-6">
        <h1 class="text-4xl font-bold mb-2">About Us</h1>
        <p class="text-lg text-gray-700">
          We’re here to prepare and empower you for the future of work.
        </p>
      </div>
  
      <!-- Mission Section -->
      <div class="mission-section bg-yellow-100 p-6 border-4 border-black rounded-lg shadow-lg mb-8">
        <h2 class="text-3xl font-semibold mb-4">Our Mission</h2>
        <p class="text-lg text-gray-700 leading-relaxed">
          At <strong>Thryve</strong>, we believe that the future belongs to those who are prepared. Our mission is to empower individuals by providing them with the skills and knowledge needed to thrive in tomorrow’s workforce. Through innovative, hands-on learning experiences, we help people stay ahead of the curve—whether it’s mastering emerging technologies, developing future-forward soft skills, or adapting to new ways of working.
        </p>
        <p class="text-lg text-gray-700 leading-relaxed mt-4">
          But we don’t stop at preparation. We aim to lead the transformation of work itself. By equipping people to innovate, collaborate, and embrace new work environments, we’re driving a movement that redefines how, where, and why we work. Together, we’re not just preparing for the future—we’re creating it.
        </p>
      </div>
  
      <!-- Values Section -->
      <div class="values-section grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="value-card bg-white p-4 border-4 border-black shadow-brutal rounded-lg text-center">
          <h3 class="text-xl font-bold mb-2">Innovation</h3>
          <p class="text-gray-700">
            We constantly strive to push boundaries, creating new ways for people to learn and work.
          </p>
        </div>
        <div class="value-card bg-white p-4 border-4 border-black shadow-brutal rounded-lg text-center">
          <h3 class="text-xl font-bold mb-2">Collaboration</h3>
          <p class="text-gray-700">
            We believe the future of work thrives on collaboration. We foster environments where people work better together.
          </p>
        </div>
        <div class="value-card bg-white p-4 border-4 border-black shadow-brutal rounded-lg text-center">
          <h3 class="text-xl font-bold mb-2">Adaptability</h3>
          <p class="text-gray-700">
            Our programs are designed to help people navigate the ever-changing landscape of modern work.
          </p>
        </div>
        <div class="value-card bg-white p-4 border-4 border-black shadow-brutal rounded-lg text-center">
          <h3 class="text-xl font-bold mb-2">Empowerment</h3>
          <p class="text-gray-700">
            We give people the tools they need to take control of their careers and embrace new opportunities.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AboutUs",
  };
  </script>
  
  <style scoped>
  .about-us-container {
    max-width: 900px;
    margin: auto;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 6px 6px 0px #000;
  }
  
  .mission-section {
    background-color: #faf089; /* Light yellow background */
  }
  
  .value-card {
    box-shadow: 6px 6px 0px #000;
  }
  
  .text-center {
    text-align: center;
  }
  
  .shadow-brutal {
    box-shadow: 6px 6px 0px #000;
  }
  
  p {
    color: #4a5568;
  }
  
  h1, h2, h3 {
    color: #2d3748;
  }
  </style>
  