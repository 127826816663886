<template>
    <div class="scenario-container bg-white p-6 rounded-lg shadow-md transition-all">
      <h2 class="text-xl font-semibold mb-4">Conflict with Your Manager</h2>
      <p class="mb-6">{{ currentScenario.question }}</p>
      <div class="options grid grid-cols-1 gap-4">
        <button 
          v-for="(option, index) in currentScenario.options" 
          :key="index" 
          @click="handleChoice(option)" 
          class="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-200 ease-in-out">
          {{ option.text }}
        </button>
      </div>
      <transition name="fade">
        <FeedbackComponent v-if="feedback" :feedback="feedback" />
      </transition>
    </div>
  </template>
  
  <script>
  import FeedbackComponent from './FeedbackComponent.vue';
  
  export default {
    components: {
      FeedbackComponent,
    },
    data() {
      return {
        currentScenario: {
          question: "Your manager seems unhappy with your performance. How do you approach the conversation?",
          options: [
            { text: "Ignore it and hope it gets better", feedback: "This approach may lead to further misunderstandings." },
            { text: "Directly ask your manager for feedback in a private meeting", feedback: "Good choice! This shows initiative and openness to improvement." },
            { text: "Discuss your concerns with coworkers first", feedback: "While seeking advice can be helpful, addressing the issue directly with your manager is more effective." }
          ],
        },
        feedback: null,
      };
    },
    methods: {
      handleChoice(option) {
        this.feedback = option.feedback;
      },
    },
  };
  </script>
  
  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  </style>
  