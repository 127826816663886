<!-- src/components/Carousel.vue -->
<template>
    <div class="carousel-container overflow-hidden">
      <div
        class="carousel-track flex transition-transform duration-500 ease-in-out"
        :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
      >
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="carousel-slide w-full flex-shrink-0 flex flex-col items-center text-center p-8"
        >
          <h2 class="text-3xl font-bold text-gray-800 mb-4">{{ slide.title }}</h2>
          <p class="text-lg text-gray-700">{{ slide.text }}</p>
        </div>
      </div>
      <div class="carousel-controls flex justify-center mt-4">
        <!-- <button
          v-for="(slide, index) in slides"
          :key="index"
          @click="goToSlide(index)"
          :class="['w-3 h-3 rounded-full mx-1', { 'bg-gray-800': currentSlide === index, 'bg-gray-300': currentSlide !== index }]"
        ></button> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentSlide: 0,
        slides: [
          { title: 'Stay Ahead in a Rapidly Changing World', text: 'The workplace is evolving faster than ever. New technologies, shifting roles, and emerging skills are transforming industries. Upskilling is no longer optional—it\'s essential to stay competitive.' },
          { title: 'Boost Your Confidence and Performance', text: 'When you upskill, you don\'t just learn new abilities—you gain confidence, improve your performance, and stand out in your team. Thryve provides real-world scenarios to sharpen your skills and excel at work.' },
          { title: 'Unlock New Opportunities', text: 'Upskilling opens doors to new career opportunities, promotions, and higher pay. Whether you\'re eyeing a leadership role or switching careers, Thryve equips you with the skills needed to achieve your goals.' },
          { title: 'Be Prepared for the Future of Work', text: 'The future of work is unpredictable. Automation and AI are reshaping job requirements. Thryve helps you adapt by continuously learning and mastering the skills that matter most in today\'s and tomorrow\'s workplace.' },
          { title: 'Invest in Yourself—It Pays the Best Interest', text: 'Investing time in learning and development is the best way to secure your career. Thryve\'s interactive scenarios make upskilling engaging, relevant, and directly applicable to your daily work challenges.' },
          { title: 'Thrive in Your Career with Thryve', text: 'Thryve is more than a learning platform—it\'s your partner in professional growth. From handling tough conversations to mastering leadership skills, Thryve provides the tools you need to thrive in your career.' },
          { title: 'Join a Community of Growth-Oriented Professionals', text: 'You\'re not alone on this journey. Thryve connects you with a community of like-minded professionals dedicated to growth and excellence. Learn, share, and succeed together.' }
        ],
      };
    },
    methods: {
      goToSlide(index) {
        this.currentSlide = index;
      },
    },
    mounted() {
      setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      }, 5000); // Auto-scroll every 5 seconds
    },
  };
  </script>
  
  <style scoped>
  .carousel-container {
    max-width: 800px;
    margin: 0 auto;
  }
  .carousel-slide {
    min-height: 200px;
  }
  .carousel-controls button {
    transition: background-color 0.3s;
  }
  </style>
  