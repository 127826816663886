<template>
  <div class="dashboard-container p-6">
    <h2 class="text-3xl font-bold mb-4">Welcome, {{ userName }}</h2>

    <!-- Container for Daily Challenge and Level Up Labs -->
    <div class="flex space-x-4 mb-6">
      <!-- Daily Challenge Tracker -->
      <div class="daily-challenge-tracker p-4 bg-white border-4 border-black shadow-brutal rounded-lg flex-1 text-center">
        <h3 class="text-xl font-bold mb-2">Daily Challenge</h3>
        <p class="text-gray-600">Keep your streak alive by completing a challenge every day!</p>
        <div class="streak-counter">
          <span class="day-count">{{ streakCount }}</span>
          <p class="text-gray-600">Days in a row</p>
        </div>
        <div v-if="showReward" class="reward-message text-green-500 font-bold mt-4 animate-bounce">
          🏆 You’ve earned a reward!
        </div>
      </div>

      <!-- Level Up Labs - Scrollable Courses Section -->
      <div class="level-up-labs p-4 bg-yellow-100 border-4 border-black shadow-brutal rounded-lg flex-1">
        <h3 class="text-2xl font-semibold mb-2">Level Up Labs</h3>
        <p class="text-gray-600">Take interactive courses, learn new skills, and earn badges to showcase your expertise.</p>
        
        <!-- Scrollable Courses -->
        <div class="lab-courses-container overflow-x-auto whitespace-nowrap mt-4 space-x-4">
          <!-- DataMastery Badge Course -->
          <div class="lab-course inline-block bg-white p-4 border-4 border-black rounded-lg w-64 mr-4">
            <h4 class="text-xl font-bold mb-2">DataMastery Badge</h4>
            <p class="text-gray-700 mb-4">Learn data analysis with hands-on examples and earn your first badge!</p>
            <button @click="goToCourse('LevelUpLabs')" class="px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white rounded-lg shadow">
              Start Course
            </button>
          </div>

          <!-- Communication Wiz Course -->
          <div class="lab-course inline-block bg-white p-4 border-4 border-black rounded-lg w-64 mr-4">
            <h4 class="text-xl font-bold mb-2">Communication Wiz</h4>
            <p class="text-gray-700 mb-4">Improve your workplace communication through AI-powered scenarios.</p>
            <button @click="goToCourse('CommunicationCourse')" class="px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white rounded-lg shadow">
              Start Course
            </button>
          </div>

          <!-- Mock Course 1: Leadership Skills -->
          <div class="lab-course inline-block bg-white p-4 border-4 border-black rounded-lg w-64 mr-4">
            <h4 class="text-xl font-bold mb-2">Leadership Skills</h4>
            <p class="text-gray-700 mb-4">Develop your leadership abilities and learn how to effectively lead a team.</p>
            <button @click="goToCourse('LeadershipCourse')" class="px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white rounded-lg shadow">
              Start Course
            </button>
          </div>

          <!-- Mock Course 2: Time Management -->
          <div class="lab-course inline-block bg-white p-4 border-4 border-black rounded-lg w-64">
            <h4 class="text-xl font-bold mb-2">Time Management</h4>
            <p class="text-gray-700 mb-4">Learn to manage your time effectively and increase productivity.</p>
            <button @click="goToCourse('TimeManagementCourse')" class="px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white rounded-lg shadow">
              Start Course
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Liked Scenarios Section -->
    <div class="scenarios-section mt-6">
      <h3 class="text-2xl font-semibold mb-2">Liked Scenarios</h3>
      <div v-if="likedScenarios.length > 0" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <ScenarioCard
          v-for="scenario in likedScenarios"
          :key="scenario.id"
          :title="scenario.title"
          :description="scenario.description"
          :image="scenario.image"
          :scenarioId="scenario.id"
          @click="goToScenario(scenario.id)"
        ></ScenarioCard>
      </div>
      <p v-else>No liked scenarios yet.</p>
    </div>

    <!-- Button to go back to Home View -->
    <button
      @click="goToHome"
      class="mb-6 px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white font-semibold rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out"
    >
      Go to Scenarios
    </button>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '../supabase';
import ScenarioCard from './ScenarioCard.vue';
import { scenarios } from '../scenarios.js';

export default {
  name: 'DashboardView',
  components: {
    ScenarioCard,
  },
  setup() {
    const user = ref(null);
    const likedScenarios = ref([]);
    const streakCount = ref(0);
    const showReward = ref(false);
    const router = useRouter();

    const fetchUserData = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error);
        return;
      }
      user.value = data.user;

      if (user.value) {
        const { data: likedData, error } = await supabase
          .from('liked_scenarios')
          .select('scenario_id')
          .eq('auth_user_id', user.value.id);

        if (error) {
          console.error('Error fetching liked scenarios:', error);
          return;
        }

        likedScenarios.value = scenarios.filter((scenario) =>
          likedData.some((liked) => liked.scenario_id === scenario.id)
        );
      }
    };

    onMounted(() => {
      fetchUserData();
    });

    const userName = computed(() => user.value?.user_metadata?.username || 'User');
    const goToScenario = (scenarioId) => {
      if (scenarioId) {
        router.push({ name: 'ScenarioDetail', params: { id: scenarioId } });
      }
    };
    const goToHome = () => {
      router.push({ name: 'HomeView' });
    };
    const goToCourse = (courseName) => {
      router.push({ name: courseName });
    };

    return {
      userName,
      likedScenarios,
      streakCount,
      showReward,
      goToScenario,
      goToHome,
      goToCourse,
    };
  },
};
</script>

<style scoped>
.dashboard-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.daily-challenge-tracker,
.level-up-labs {
  flex: 1;
  min-width: 45%;
}

.daily-challenge-tracker {
  border-radius: 12px;
  box-shadow: 10px 10px 0px #000;
}

.streak-counter {
  font-size: 48px;
  font-weight: bold;
  color: #4ecdc4;
  margin-top: 10px;
}

.reward-message {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.level-up-labs {
  background-color: #fefcbf;
  box-shadow: 10px 10px 0px #000;
}

.lab-courses-container {
  display: flex;
  overflow-x: auto;
}

.lab-course {
  width: 280px;
  min-width: 280px;
}
</style>
