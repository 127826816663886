<template>
    <div class="feedback bg-green-100 p-4 mt-4 border-l-4 border-green-500">
      <p class="text-green-700">{{ feedback }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: ['feedback'],
  };
  </script>
  
  <style scoped>
  /* Tailwind styling used for clean design */
  </style>
  