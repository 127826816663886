<!-- src/components/ContactUs.vue -->
<template>
    <div class="contact-us-container p-8 bg-white border-4 border-black rounded-lg shadow-lg mt-10 max-w-4xl mx-auto">
      <h2 class="text-3xl font-bold mb-4">Contact Us</h2>
      <p class="text-lg text-gray-700 mb-6">
        We’d love to hear from you! Whether you have feedback, questions, or need support, feel free to reach out to us.
      </p>
      <div class="contact-info bg-gray-100 p-4 border-4 border-black rounded-lg">
        <p class="text-gray-600">
          Email: <a href="mailto:jerome@thryveconnect.com" class="text-blue-600 underline hover:text-blue-400">jerome@thryveconnect.com</a>
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactUs',
  };
  </script>
  
  <style scoped>
  .contact-us-container {
    background-color: #f9f9f9;
    box-shadow: 6px 6px 0px #000;
    border-radius: 8px;
  }
  </style>
  