<template>
  <div class="p-8 bg-white border-4 border-border shadow-brutal rounded-lg">
    <div class="flex items-center justify-between mb-4">
      <h1 class="text-4xl font-bold text-gray-800">{{ scenario.title }}</h1>
      <!-- Heart Icon Button -->
      <button @click="toggleLike" aria-label="Like">
        <svg
          :class="{ 'text-red-500': isLiked, 'text-gray-400': !isLiked }"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          width="32"
          height="32"
        >
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41 1 4.5 2.09C13.09 4 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          />
        </svg>
      </button>
    </div>
    <p class="text-gray-700 mb-6">{{ scenario.description }}</p>

    <div class="bg-accent p-6 rounded-lg border-4 border-border shadow-brutal">
      <h2 class="text-2xl font-semibold text-gray-800 mb-2">Scenario Interaction</h2>
      <p class="text-gray-900 mb-4">{{ scenario.interactionDescription }}</p>

      <div class="space-y-4">
        <button
          v-for="(option, index) in scenario.options"
          :key="index"
          @click="handleChoice(option)"
          class="block w-full text-left px-4 py-3 bg-secondary text-white font-bold rounded-lg border-4 border-border shadow-brutal hover:bg-primary transition duration-200 ease-in-out"
        >
          {{ option.text }}
        </button>
      </div>

      <div v-if="feedback" class="mt-6 p-4 bg-white border-4 border-border shadow-brutal text-green-700">
        <p>{{ feedback }}</p>
      </div>
    </div>

    <!-- Back to Home Button -->
    <button
      @click="goBackToHome"
      class="mt-6 px-4 py-2 bg-gradient-to-r from-green-400 via-pink-500 to-red-500 text-white font-semibold rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out"
    >
      Back to Home
    </button>

    <!-- Modal for signup when user is not logged in -->
    <AuthModal
      v-if="showSignUpModalVisible"
      :type="modalType"
      @close="handleModalClose"
      @update:type="updateModalType"
    />
  </div>
</template>

<script>
import { scenarios } from '../scenarios';
import { supabase } from '../supabase';
import AuthModal from './AuthModal.vue'; // Import AuthModal

export default {
  components: {
    AuthModal,
  },
  props: ['id'],
  data() {
    return {
      scenario: {},
      feedback: null,
      isLiked: false,
      user: null,
      showSignUpModalVisible: false, // Track modal visibility
      modalType: 'signup', // Define the modal type
    };
  },
  created() {
    this.scenario = scenarios.find((s) => s.id === parseInt(this.id, 10));
    this.fetchUser();
    this.checkLikedStatus();
  },
  methods: {
    async fetchUser() {
      const { data } = await supabase.auth.getUser();
      this.user = data.user;
    },
    async checkLikedStatus() {
      if (!this.user || !this.scenario.id) return;

      try {
        const { data: likedData } = await supabase
          .from('liked_scenarios')
          .select('scenario_id')
          .eq('auth_user_id', this.user.id)
          .eq('scenario_id', this.scenario.id)
          .single();

        this.isLiked = !!likedData;
      } catch (error) {
        if (error.code !== 'PGRST116') {
          console.error('Error checking liked status:', error);
        }
      }
    },
    async toggleLike() {
      if (!this.user) {
        this.showSignUpModal(); // Show signup modal if the user is not logged in
        return;
      }

      try {
        if (this.isLiked) {
          const { error } = await supabase
            .from('liked_scenarios')
            .delete()
            .eq('auth_user_id', this.user.id)
            .eq('scenario_id', this.scenario.id);

          if (error) throw error;
          this.isLiked = false;
        } else {
          const { error } = await supabase
            .from('liked_scenarios')
            .insert([{ auth_user_id: this.user.id, scenario_id: this.scenario.id }]);

          if (error) throw error;
          this.isLiked = true;
        }
      } catch (error) {
        console.error('Error toggling like:', error);
        alert('An error occurred. Please try again.');
      }
    },
    handleChoice(option) {
      this.feedback = option.feedback;
    },
    goBackToHome() {
      this.$router.push({ name: 'HomeView' });
    },
    showSignUpModal() {
      this.showSignUpModalVisible = true; // Show the modal when called
    },
    handleModalClose() {
      this.showSignUpModalVisible = false;
      this.fetchUser(); // Re-fetch user status after the modal is closed
    },
    updateModalType(newType) {
      this.modalType = newType;
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
