<template>
  <header class="bg-gradient-to-r from-primaryGradientStart via-secondary to-primaryGradientEnd p-6 flex justify-between items-center shadow-brutal">
    <!-- Company Name with Larger, More Prominent Text -->
    <router-link to="/" class="text-white font-extrabold text-4xl tracking-wide">
      Thryve
    </router-link>
    
    <div class="flex space-x-6">
      <!-- Show Login and Sign Up buttons only when the user is NOT logged in -->
      <template v-if="!isLoggedIn">
        <button
          @click="showModal('login')"
          class="text-white text-lg hover:underline transition duration-200"
        >
          Login
        </button>
        <button
          @click="showModal('signup')"
          class="bg-white text-black px-4 py-2 rounded-lg shadow hover:bg-gray-100 transition duration-200"
        >
          Sign Up
        </button>
      </template>

      <!-- Show Sign Out button only when the user IS logged in -->
      <button
        v-if="isLoggedIn"
        @click="handleLogout"
        class="text-white bg-red-500 px-4 py-2 rounded-lg shadow hover:bg-red-600 transition duration-200"
      >
        Sign Out
      </button>
    </div>

    <!-- Modal for login/signup -->
    <AuthModal v-if="isModalVisible" :type="modalType" @close="closeModal" />
  </header>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '../supabase';
import AuthModal from './AuthModal.vue';
import { useToast } from 'vue-toastification';

export default {
  components: {
    AuthModal,
  },
  setup() {
    const isModalVisible = ref(false);
    const modalType = ref('login');
    const user = ref(null);
    const router = useRouter();
    const toast = useToast();

    const fetchUser = async () => {
      const { data } = await supabase.auth.getUser();
      user.value = data.user;
    };

    const handleAuthChange = () => {
      supabase.auth.onAuthStateChange((event, session) => {
        user.value = session?.user || null;
      });
    };

    onMounted(() => {
      fetchUser();
      handleAuthChange();
    });

    const isLoggedIn = computed(() => !!user.value);

    const showModal = (type) => {
      modalType.value = type;
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const handleLogout = async () => {
      try {
        const { error } = await supabase.auth.signOut();
        if (error) throw error;
        user.value = null;
        toast.success('Successfully signed out!');
        router.push('/');
      } catch (error) {
        console.error('Error during sign out:', error.message);
        toast.error('Error signing out. Please try again.');
      }
    };

    return {
      isModalVisible,
      modalType,
      showModal,
      closeModal,
      handleLogout,
      isLoggedIn,
    };
  },
};
</script>

<style scoped>
header {
  position: relative;
  z-index: 10;
}
</style>