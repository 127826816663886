<!-- src/components/BlogDetailsView.vue -->
<template>
  <div class="blog-details-container p-8 bg-white border-4 border-black rounded-lg shadow-lg mt-10 max-w-4xl mx-auto">
    <div v-if="post" class="blog-details">
      <h1 class="text-4xl font-bold mb-4">{{ post.title }}</h1>
      <img :src="post.image" alt="Blog Post Image" class="w-full h-64 object-cover rounded-lg mb-6" />
      <div class="blog-content text-lg leading-relaxed text-gray-700">
        <p class="mb-4">
          The job market is constantly evolving, with new technologies and societal shifts shaping the careers of tomorrow. 
          If you're wondering which jobs will be in high demand in the next decade, we've got you covered. Here are the top 20 jobs of the future:
        </p>

        <ul class="list-disc list-inside mb-4 space-y-4">
          <li><strong>AI Specialist:</strong> AI specialists design and build AI models that help businesses automate processes and make data-driven decisions.</li>
          <li><strong>Data Scientist:</strong> Data scientists analyze large datasets to uncover insights, driving business strategies and innovations.</li>
          <li><strong>Sustainable Energy Engineer:</strong> These engineers focus on renewable energy solutions to help combat climate change.</li>
          <li><strong>Robotics Engineer:</strong> Robotics engineers create systems that can perform tasks traditionally done by humans.</li>
          <li><strong>Cybersecurity Expert:</strong> As cyber threats increase, cybersecurity experts are vital in protecting digital assets.</li>
          <li><strong>Genetic Counselor:</strong> These professionals guide patients on their genetic health risks and testing.</li>
          <li><strong>Cloud Computing Specialist:</strong> Cloud specialists manage cloud infrastructures, crucial for data storage and remote operations.</li>
          <li><strong>Blockchain Developer:</strong> Developers in this field focus on creating decentralized applications and platforms for secure transactions.</li>
          <li><strong>VR/AR Developer:</strong> Virtual and Augmented Reality developers create immersive digital experiences.</li>
          <li><strong>Digital Marketing Expert:</strong> These professionals help businesses grow through data-driven marketing strategies.</li>
          <li><strong>Biotechnology Engineer:</strong> Biotechnology engineers innovate in healthcare, agriculture, and environmental conservation.</li>
          <li><strong>Elder Care Specialist:</strong> With an aging population, elder care professionals will be in high demand.</li>
          <li><strong>Autonomous Vehicle Engineer:</strong> These engineers develop self-driving technology for transportation systems.</li>
          <li><strong>E-Commerce Specialist:</strong> E-commerce professionals optimize online retail platforms for better user experiences.</li>
          <li><strong>3D Printing Technician:</strong> This role involves operating 3D printing technology to produce parts and products.</li>
          <li><strong>Environmental Engineer:</strong> Environmental engineers design solutions to reduce pollution and combat climate change.</li>
          <li><strong>Remote Work Coordinator:</strong> As remote work continues to grow, coordinators manage virtual teams.</li>
          <li><strong>Healthcare Administrator:</strong> Healthcare administrators ensure smooth operations within hospitals and clinics.</li>
          <li><strong>Quantum Computing Expert:</strong> Quantum computing experts work on solving complex computational problems.</li>
          <li><strong>Space Tourism Guide:</strong> As space tourism becomes a reality, guides will help passengers explore outer space.</li>
        </ul>

        <p class="text-gray-600 mt-6">These careers are at the forefront of the future job market. Investing in these skills today will help you stay ahead tomorrow.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      post: null,
    };
  },
  created() {
    const postId = this.$route.params.id;
    const blogPosts = [
      {
        id: 1,
        title: "The Top 20 Jobs of the Future",
        image: "/images/future-jobs-banner.jpg",
        content: "Discover the careers that will dominate the future job market...",
      },
      {
        id: 2,
        title: "How to Build a Career in Data Science",
        image: "/images/data-science-career.jpg",
        content: "Data science is one of the fastest-growing fields in tech. Here's how to get started...",
      },
      {
        id: 3,
        title: "Mastering Remote Work: Top Tips",
        image: "/images/remote-work.jpg",
        content: "Remote work is here to stay. Learn how to maximize productivity while working from home...",
      },
    ];

    this.post = blogPosts.find((post) => post.id === parseInt(postId));
  },
};
</script>

<style scoped>
.blog-details-container {
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 6px 6px 0px #000;
}

.blog-details img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 6px 6px 0px #000;
}

.blog-content ul {
  padding-left: 1.5rem;
}

.blog-content ul li {
  margin-bottom: 1rem;
}

.blog-content p {
  color: #555;
}

.shadow-lg {
  box-shadow: 6px 6px 0px #000;
}
</style>
