<!-- src/components/Footer.vue -->
<template>
    <footer class="bg-gray-800 text-white py-6 mt-8">
      <div class="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <!-- Footer Logo and Description -->
        <div class="mb-4 md:mb-0">
          <h2 class="text-lg font-bold">Thryve</h2>
          <p class="text-sm text-gray-400">Empowering you to grow and excel in the modern workplace.</p>
        </div>
  
        <!-- Footer Links -->
        <div class="flex space-x-6">
        <router-link to="/about-us" class="text-gray-400 hover:text-white transition">About Us</router-link>
        <a href="/contact-us" class="text-gray-400 hover:text-white transition">Contact Us</a>
        <a href="#" class="text-gray-400 hover:text-white transition">Privacy Policy</a>
          <a href="#" class="text-gray-400 hover:text-white transition">Terms of Service</a>
        </div>
  
        <!-- Social Media Icons -->
        <div class="flex space-x-4 mt-4 md:mt-0">
          <a href="#" class="text-gray-400 hover:text-white transition">
            <i class="fab fa-facebook-f"></i> <!-- Replace with appropriate icons or images -->
          </a>
          <a href="#" class="text-gray-400 hover:text-white transition">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="#" class="text-gray-400 hover:text-white transition">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="#" class="text-gray-400 hover:text-white transition">
            <i class="fab fa-instagram"></i>
          </a>
        </div>
      </div>
  
      <!-- Copyright Section -->
      <div class="mt-4 text-center text-gray-500 text-sm">
        &copy; {{ new Date().getFullYear() }} Thryve. All rights reserved.
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentYear: new Date().getFullYear(),
      };
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styling here if needed */
  </style>
  