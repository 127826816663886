<template>
  <div class="skills-assessment-container p-8 bg-white border-4 border-black rounded-lg shadow-lg mt-10 max-w-2xl mx-auto">
    <h2 class="text-3xl font-bold text-center mb-4">Discover Your Skills</h2>

    <!-- First Step: Basic Information -->
    <div v-if="currentStep === 1" class="step">
      <h3 class="text-2xl font-semibold mb-4">Basic Information</h3>
      <input
        v-model="firstName"
        type="text"
        placeholder="First Name"
        class="input-field px-3 py-2 rounded-lg text-black border-2 border-black shadow-brutal w-full mb-4"
        required
      />
      <input
        v-model="lastName"
        type="text"
        placeholder="Last Name"
        class="input-field px-3 py-2 rounded-lg text-black border-2 border-black shadow-brutal w-full mb-4"
        required
      />
      <input
        v-model="zipCode"
        type="text"
        placeholder="Zip Code"
        class="input-field px-3 py-2 rounded-lg text-black border-2 border-black shadow-brutal w-full mb-4"
        required
      />
      <input
        v-model="email"
        type="email"
        placeholder="Enter your email"
        class="input-field px-3 py-2 rounded-lg text-black border-2 border-black shadow-brutal w-full mb-4"
        required
      />
      <button @click="nextStep" class="next-button" :disabled="!email">Next</button>
    </div>

    <!-- Communication Skills -->
    <div v-if="currentStep === 2" class="step">
      <h3 class="text-2xl font-semibold mb-4">Rate Your Communication Skills</h3>
      <div class="button-group">
        <button
          v-for="(option, index) in communicationOptions"
          :key="index"
          @click="selectCommunicationSkill(index)"
          :class="['skill-button', selectedCommunication === index ? 'selected' : '']"
        >
          {{ option }}
        </button>
      </div>

      <div class="navigation-buttons mt-6 flex justify-between">
        <button @click="previousStep" class="back-button">Back</button>
        <button @click="nextStep" class="next-button" :disabled="selectedCommunication === null">Next</button>
      </div>
    </div>

    <!-- Technical Skills -->
    <div v-if="currentStep === 3" class="step">
      <h3 class="text-2xl font-semibold mb-4">Rate Your Technical Skills</h3>
      <div class="button-group">
        <button
          v-for="(option, index) in technicalOptions"
          :key="index"
          @click="selectTechnicalSkill(index)"
          :class="['skill-button', selectedTechnical === index ? 'selected' : '']"
        >
          {{ option }}
        </button>
      </div>

      <div class="navigation-buttons mt-6 flex justify-between">
        <button @click="previousStep" class="back-button">Back</button>
        <button @click="nextStep" class="next-button" :disabled="selectedTechnical === null">Next</button>
      </div>
    </div>

    <!-- Leadership Skills -->
    <div v-if="currentStep === 4" class="step">
      <h3 class="text-2xl font-semibold mb-4">Rate Your Leadership Skills</h3>
      <div class="button-group">
        <button
          v-for="(option, index) in leadershipOptions"
          :key="index"
          @click="selectLeadershipSkill(index)"
          :class="['skill-button', selectedLeadership === index ? 'selected' : '']"
        >
          {{ option }}
        </button>
      </div>

      <div class="navigation-buttons mt-6 flex justify-between">
        <button @click="previousStep" class="back-button">Back</button>
        <button @click="nextStep" class="next-button" :disabled="selectedLeadership === null">Next</button>
      </div>
    </div>

    <!-- Career Goals -->
    <div v-if="currentStep === 5" class="step">
      <h3 class="text-2xl font-semibold mb-4">What Are Your Career Goals?</h3>
      <div class="button-group">
        <button
          v-for="(option, index) in careerGoalOptions"
          :key="index"
          @click="selectCareerGoal(index)"
          :class="['skill-button', selectedCareerGoal === index ? 'selected' : '']"
        >
          {{ option }}
        </button>
      </div>

      <div class="navigation-buttons mt-6 flex justify-between">
        <button @click="previousStep" class="back-button">Back</button>
        <button @click="nextStep" class="next-button" :disabled="selectedCareerGoal === null">Next</button>
      </div>
    </div>

    <!-- Work Motivation -->
    <div v-if="currentStep === 6" class="step">
      <h3 class="text-2xl font-semibold mb-4">What Excites You About Work?</h3>
      <div class="button-group">
        <button
          v-for="(option, index) in motivationOptions"
          :key="index"
          @click="selectWorkMotivation(index)"
          :class="['skill-button', selectedMotivation === index ? 'selected' : '']"
        >
          {{ option }}
        </button>
      </div>

      <div class="navigation-buttons mt-6 flex justify-between">
        <button @click="previousStep" class="back-button">Back</button>
        <button @click="submitAssessment" class="submit-button" :disabled="selectedMotivation === null">Submit</button>
      </div>
    </div>

    <p v-if="message" class="mt-4 text-green-500 text-center">{{ message }}</p>
  </div>
</template>

<script>
import { ref } from 'vue';
import { supabase } from '../supabase';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const zipCode = ref('');
    const email = ref('');
    const communicationSkills = ref('');
    const technicalSkills = ref('');
    const leadershipSkills = ref('');
    const careerGoals = ref('');
    const workMotivation = ref('');
    const message = ref('');
    const toast = useToast();
    const currentStep = ref(1);
    const router = useRouter();

    // Skill options with fun answers
    const communicationOptions = [
      "I don't communicate at all",
      'I talk on the phone from time to time',
      'I email a little',
      "I'm an email wizard but don't speak on the phone much",
      'If emailing and talking on the phone was a sport, I’d sign a multimillion-dollar contract',
    ];

    const technicalOptions = [
      'Technology? What’s that?',
      'I can turn on a computer… most days.',
      'I know my way around Excel.',
      'I’m a coding ninja after hours.',
      'I eat algorithms for breakfast.',
    ];

    const leadershipOptions = [
      'I follow the leader… closely.',
      'I offer suggestions… occasionally.',
      'I take the lead when no one else will.',
      'I manage teams like a pro.',
      'I’m the CEO everyone wishes they had.',
    ];

    const careerGoalOptions = [
      'Climb the corporate ladder and lead a team.',
      'Start my own business and be my own boss.',
      'Become a specialist and master my craft.',
      'Find a job that pays the bills with minimal stress.',
      'Change the world one step at a time.',
    ];

    const motivationOptions = [
      'The paycheck—let’s be real.',
      'Solving complex problems keeps me going.',
      'Helping others and making a difference.',
      'Collaborating with a great team.',
      'Having the freedom to create and innovate.',
    ];

    const selectedCommunication = ref(null);
    const selectedTechnical = ref(null);
    const selectedLeadership = ref(null);
    const selectedCareerGoal = ref(null);
    const selectedMotivation = ref(null);

    const nextStep = () => {
      currentStep.value += 1;
    };

    const previousStep = () => {
      currentStep.value -= 1;
    };

    const selectCommunicationSkill = (index) => {
      communicationSkills.value = index + 1;
      selectedCommunication.value = index;
    };

    const selectTechnicalSkill = (index) => {
      technicalSkills.value = index + 1;
      selectedTechnical.value = index;
    };

    const selectLeadershipSkill = (index) => {
      leadershipSkills.value = index + 1;
      selectedLeadership.value = index;
    };

    const selectCareerGoal = (index) => {
      careerGoals.value = careerGoalOptions[index];
      selectedCareerGoal.value = index;
    };

    const selectWorkMotivation = (index) => {
      workMotivation.value = motivationOptions[index];
      selectedMotivation.value = index;
    };

    const submitAssessment = async () => {
      if (!email.value || !firstName.value || !lastName.value || !zipCode.value) {
        toast.error('Please complete all required fields.');
        return;
      }

      try {
        const { error } = await supabase.from('skills_assessment_responses').insert([
          {
            first_name: firstName.value,
            last_name: lastName.value,
            zip_code: zipCode.value,
            email: email.value,
            communication_skills: communicationSkills.value,
            technical_skills: technicalSkills.value,
            leadership_skills: leadershipSkills.value,
            career_goals: careerGoals.value,
            work_motivation: workMotivation.value,
          },
        ]);

        if (error) throw error;

        toast.success('Thank you for completing the assessment!');
        message.value = 'Your responses have been recorded. We’ll help guide your career journey!';

        // Reset form fields
        firstName.value = '';
        lastName.value = '';
        zipCode.value = '';
        email.value = '';
        currentStep.value = 1;
        selectedCommunication.value = null;
        selectedTechnical.value = null;
        selectedLeadership.value = null;
        selectedCareerGoal.value = null;
        selectedMotivation.value = null;

        // Redirect to HomeView
        goBackToHome();
      } catch (error) {
        toast.error('Error submitting assessment. Please try again later.');
      }
    };

    const goBackToHome = () => {
      router.push({ name: 'HomeView' });
    };

    return {
      firstName,
      lastName,
      zipCode,
      email,
      communicationSkills,
      technicalSkills,
      leadershipSkills,
      careerGoals,
      workMotivation,
      message,
      currentStep,
      nextStep,
      previousStep,
      submitAssessment,
      communicationOptions,
      technicalOptions,
      leadershipOptions,
      careerGoalOptions,
      motivationOptions,
      selectCommunicationSkill,
      selectTechnicalSkill,
      selectLeadershipSkill,
      selectCareerGoal,
      selectWorkMotivation,
      selectedCommunication,
      selectedTechnical,
      selectedLeadership,
      selectedCareerGoal,
      selectedMotivation,
      goBackToHome,
    };
  },
};
</script>

<style scoped>
.skills-assessment-container {
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
}

.shadow-brutal {
  box-shadow: 6px 6px 0px #000;
}

.skill-button {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  box-shadow: 3px 3px 0px #000;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.skill-button:hover {
  transform: scale(1.05);
}

.selected {
  background-color: #68d391;
  color: white;
}

.next-button,
.back-button,
.submit-button {
  font-weight: bold;
  padding: 10px 20px;
  border: 2px solid #000;
  border-radius: 8px;
  box-shadow: 3px 3px 0px #000;
  transition: all 0.3s ease-in-out;
}

.next-button:hover,
.back-button:hover,
.submit-button:hover {
  transform: scale(1.05);
}
</style>
