// src/scenarios.js
export const scenarios = [
  {
    id: 1,
    title: 'Conflict Resolution with a Manager',
    description: 'Learn how to handle a tough conversation with your manager.',
    image: 'https://images.unsplash.com/photo-1595152772835-219674b2a8a6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDF8fG1lZXRpbmd8ZW58MHx8fHwxNjk0MTA0MDc5&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your manager seems unhappy with your performance. How do you approach the conversation?',
    options: [
      { text: 'Ignore it and hope it gets better', feedback: 'This approach may lead to further misunderstandings.' },
      { text: 'Directly ask your manager for feedback in a private meeting', feedback: 'Good choice! This shows initiative and openness to improvement.' },
      { text: 'Discuss your concerns with coworkers first', feedback: 'While seeking advice can be helpful, addressing the issue directly with your manager is more effective.' },
    ],
  },
  {
    id: 2,
    title: 'Introducing Yourself to a New Team Member',
    description: 'Learn the best approach to introduce yourself to a new team member.',
    image: 'https://images.unsplash.com/photo-1590608897129-79d13fcd6dce?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGdyZWV0aW5nfGVufDB8fHx8MTY5NDEwNDA4MA&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'A new colleague has joined your team. How do you introduce yourself?',
    options: [
      { text: 'Wait until they approach you', feedback: 'This may make the new team member feel unwelcome.' },
      { text: 'Introduce yourself and offer help if needed', feedback: 'Great approach! This helps them feel welcomed and supported.' },
      { text: 'Send a quick message instead of meeting in person', feedback: 'A message is okay, but a personal approach is more welcoming.' },
    ],
  },
  {
    id: 3,
    title: 'Requesting Help on a Task',
    description: 'Discover how to ask for help without appearing unprepared or disorganized.',
    image: 'https://images.unsplash.com/photo-1601233742730-7a2c5b54caa7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDN8fHdvcmt8ZW58MHx8fHwxNjk0MTA0MTI3&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You are stuck on a task. What’s the best way to ask for help?',
    options: [
      { text: 'Ask without context', feedback: 'It’s important to provide context to receive useful help.' },
      { text: 'Clearly explain what you’ve tried and where you’re stuck', feedback: 'Good choice! Providing details helps others assist effectively.' },
      { text: 'Ask someone else to take over', feedback: 'Passing off your task isn’t the best way to handle the situation.' },
    ],
  },
  {
    id: 4,
    title: 'Giving Positive Feedback to a Colleague',
    description: 'Master the art of giving constructive praise that motivates your colleagues.',
    image: 'https://images.unsplash.com/photo-1556741533-f6acd647ab1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDZ8fGZlZWRiYWNrfGVufDB8fHx8MTY5NDEwNDA5MA&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your colleague performed well on a project. How do you give feedback?',
    options: [
      { text: 'Simply say "good job"', feedback: 'While nice, it lacks specifics that encourage continuous improvement.' },
      { text: 'Highlight specific things they did well', feedback: 'This is the best approach, making your feedback meaningful and actionable.' },
      { text: 'Don’t say anything; they know they did well', feedback: 'Feedback helps reinforce positive behaviors.' },
    ],
  },
  {
    id: 5,
    title: 'Receiving Criticism Gracefully',
    description: 'Learn how to handle feedback positively, even when it’s critical.',
    image: 'https://images.unsplash.com/photo-1562564055-71e051d31e2b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDh8fGNyaXRpY2lzbXxlbnwwfHx8fDE2OTQxMDQxMDA&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your manager gives you critical feedback on your recent performance. How do you respond?',
    options: [
      { text: 'Defend your actions immediately', feedback: 'This may come off as defensive and uncooperative.' },
      { text: 'Listen, thank them for the feedback, and ask how you can improve', feedback: 'Perfect response! This shows professionalism and willingness to grow.' },
      { text: 'Ignore the feedback', feedback: 'Ignoring feedback can harm your growth and team dynamics.' },
    ],
  },
  {
    id: 6,
    title: 'Handling Interruptions in a Meeting',
    description: 'Tackle interruptions professionally without losing control of the meeting.',
    image: 'https://images.unsplash.com/photo-1603791440384-56cd371ee9c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fG1lZXRpbmd8ZW58MHx8fHwxNjk0MTA0MTA4&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'During a meeting, one participant keeps interrupting others. How do you handle it?',
    options: [
      { text: 'Let them continue', feedback: 'This can disrupt the flow of the meeting.' },
      { text: 'Politely ask them to hold their comments until others finish', feedback: 'Great choice! This maintains control without being rude.' },
      { text: 'Ignore the interruptions', feedback: 'Ignoring can create more disruption and frustration among other participants.' },
    ],
  },
  {
    id: 7,
    title: 'Dealing with a Missed Deadline',
    description: 'Learn how to communicate and manage expectations when a deadline is missed.',
    image: 'https://images.unsplash.com/photo-1573497491208-6b1acb260507?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDF8fGRlYWRsaW5lfGVufDB8fHx8MTY5NDEwNDEyMA&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You realize you won’t meet a deadline. What should you do?',
    options: [
      { text: 'Don’t inform anyone until asked', feedback: 'Proactive communication is key to maintaining trust.' },
      { text: 'Inform your manager immediately and propose a new deadline', feedback: 'Excellent! This approach shows accountability and problem-solving skills.' },
      { text: 'Rush through to finish, compromising quality', feedback: 'It’s better to address the delay than sacrifice the work quality.' },
    ],
  },
  {
    id: 8,
    title: 'Resolving a Minor Team Conflict',
    description: 'Handle small disagreements between team members to maintain a positive work environment.',
    image: 'https://images.unsplash.com/photo-1600880292062-3d6bdc8911c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDh8fGNvbmZsaWN0fGVufDB8fHx8MTY5NDEwNDEyMw&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Two team members have a minor disagreement. How do you approach resolving it?',
    options: [
      { text: 'Let them work it out themselves', feedback: 'Some conflicts need guidance to prevent escalation.' },
      { text: 'Mediate and help them find common ground', feedback: 'Perfect approach! This encourages teamwork and mutual respect.' },
      { text: 'Take sides and defend one member', feedback: 'Remaining neutral and objective is crucial in conflict resolution.' },
    ],
  },
  {
    id: 9,
    title: 'Managing a Difficult Customer',
    description: 'Techniques to handle difficult customers calmly and professionally.',
    image: 'https://images.unsplash.com/photo-1596464716121-6b0c98299b7c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDR8fGN1c3RvbWVyfGVufDB8fHx8MTY5NDEwNDEyNg&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'A customer is upset and frustrated. What’s your response?',
    options: [
      { text: 'Match their tone to assert control', feedback: 'This can escalate the situation further.' },
      { text: 'Listen, empathize, and offer a solution', feedback: 'Well done! This approach diffuses tension and focuses on resolution.' },
      { text: 'Ignore their complaints', feedback: 'Acknowledging concerns is critical to resolving issues effectively.' },
    ],
  },
  {
    id: 10,
    title: 'Asking for a Raise',
    description: 'Approach the delicate conversation of asking for a raise with confidence and justification.',
    image: 'https://images.unsplash.com/photo-1551836022-d5d88e9218df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHJhaXNlfGVufDB8fHx8MTY5NDEwNDEyOQ&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You feel you deserve a raise. How do you initiate this conversation?',
    options: [
      { text: 'Demand a raise without providing reasons', feedback: 'Supporting your request with evidence is key.' },
      { text: 'Prepare your achievements and discuss your value to the company', feedback: 'Great strategy! This shows you’ve thought about your request logically.' },
      { text: 'Threaten to leave if you don’t get a raise', feedback: 'This can harm your professional relationship.' },
    ],
  },
  {
    id: 11,
    title: 'Leading a Team Meeting',
    description: 'Learn how to effectively lead a meeting, keeping it on track and engaging.',
    image: 'https://images.unsplash.com/photo-1562564055-71e051d31e2b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGxlYWRlcnNoaXB8ZW58MHx8fHwxNjk0MTA0MTM0&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'It’s your turn to lead a team meeting. What approach do you take?',
    options: [
      { text: 'Let the conversation flow without direction', feedback: 'Structure is important to keep the meeting productive.' },
      { text: 'Set a clear agenda and keep the discussion focused', feedback: 'Perfect! This ensures the meeting achieves its goals.' },
      { text: 'Dominate the discussion without input from others', feedback: 'Encouraging participation creates a more dynamic and engaging meeting.' },
    ],
  },
  {
    id: 12,
    title: 'Addressing Team Performance Issues',
    description: 'Address team performance issues with a constructive approach that encourages improvement.',
    image: 'https://images.unsplash.com/photo-1504384308090-c894fdcc538d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDR8fGV2YWx1YXRpb258ZW58MHx8fHwxNjk0MTA0MTM2&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'A team member’s performance has been declining. How do you address it?',
    options: [
      { text: 'Criticize them publicly', feedback: 'Performance issues should be addressed privately and respectfully.' },
      { text: 'Have a private conversation to understand their challenges and offer support', feedback: 'This approach is empathetic and constructive.' },
      { text: 'Ignore it and hope it improves', feedback: 'Ignoring issues can lead to continued performance problems.' },
    ],
  },
  {
    id: 13,
    title: 'Mediating a Serious Team Conflict',
    description: 'Develop skills to mediate a serious conflict between team members and find a resolution.',
    image: 'https://images.unsplash.com/photo-1581091870621-82e9c6f212b9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fG1lZGlhdGlvbnxlbnwwfHx8fDE2OTQxMDQxMzg&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Two team members have a serious disagreement. How do you mediate?',
    options: [
      { text: 'Take one side over the other', feedback: 'Remaining neutral is critical to effective mediation.' },
      { text: 'Listen to both sides and facilitate a respectful discussion', feedback: 'Great job! This approach helps find common ground.' },
      { text: 'Ignore the conflict and hope it resolves itself', feedback: 'Active mediation is necessary to resolve serious conflicts.' },
    ],
  },
  {
    id: 14,
    title: 'Delivering Bad News to Your Team',
    description: 'Learn how to deliver bad news to your team while maintaining morale and transparency.',
    image: 'https://images.unsplash.com/photo-1607746882042-944635dfe10e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGJhZCUyMG5ld3N8ZW58MHx8fHwxNjk0MTA0MTQy&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You need to deliver bad news to your team. How do you approach it?',
    options: [
      { text: 'Minimize the issue and avoid questions', feedback: 'Transparency builds trust, even with bad news.' },
      { text: 'Be honest and direct, explaining the reasons behind the situation', feedback: 'Excellent! Clear communication helps the team understand and adapt.' },
      { text: 'Blame others to deflect responsibility', feedback: 'Taking responsibility maintains your credibility as a leader.' },
    ],
  },
  {
    id: 15,
    title: 'Handling a Public Relations Crisis',
    description: 'Strategies for managing a PR crisis and communicating effectively under pressure.',
    image: 'https://images.unsplash.com/photo-1596464716121-6b0c98299b7c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDF8fFBSJTIwY3Jpc2lzfGVufDB8fHx8MTY5NDEwNDE0NQ&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your company faces a public relations crisis. What’s your first step?',
    options: [
      { text: 'Avoid addressing the issue publicly', feedback: 'Silence can worsen the public’s perception.' },
      { text: 'Acknowledge the issue, take responsibility, and outline steps to resolve it', feedback: 'This is the most effective way to regain public trust.' },
      { text: 'Blame the customers for overreacting', feedback: 'Blaming others can severely damage your company’s reputation.' },
    ],
  },
  {
    id: 16,
    title: 'Navigating Office Politics and Ethical Dilemmas',
    description: 'Tackle complex ethical dilemmas and office politics while maintaining integrity.',
    image: 'https://images.unsplash.com/photo-1581091870621-82e9c6f212b9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGV0aGljc3xlbnwwfHx8fDE2OTQxMDQxNDg&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You witness a colleague engaging in unethical behavior. How do you handle it?',
    options: [
      { text: 'Ignore it to avoid conflict', feedback: 'Ignoring unethical behavior can harm your organization’s culture.' },
      { text: 'Report the behavior through the proper channels', feedback: 'Reporting unethical behavior is the responsible choice.' },
      { text: 'Confront the colleague aggressively', feedback: 'Approaching the situation calmly and through the correct channels is more effective.' },
    ],
  },
  {
    id: 17,
    title: 'Handling a Project Scope Creep',
    description: 'Manage unexpected changes in project scope effectively.',
    image: 'https://images.unsplash.com/photo-1515165562835-4e90e5c2e628?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHByb2plY3R8ZW58MHx8fHwxNjk0MTA0MTUy&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your project is facing scope creep due to frequent client requests. How do you handle it?',
    options: [
      { text: 'Accept all requests to keep the client happy', feedback: 'This may lead to burnout and missed deadlines; manage expectations.' },
      { text: 'Set clear boundaries and negotiate additional resources or time', feedback: 'Good approach! This keeps the project on track while addressing changes.' },
      { text: 'Ignore the changes and continue as planned', feedback: 'Ignoring changes can lead to project failure; communication is key.' },
    ],
  },
  {
    id: 18,
    title: 'Delegating Tasks Effectively',
    description: 'Learn to delegate tasks to the right people for better productivity.',
    image: 'https://images.unsplash.com/photo-1590608897129-79d13fcd6dce?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGRlbGVnYXRpb258ZW58MHx8fHwxNjk0MTA0MTUy&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You have too much on your plate. How do you delegate tasks effectively?',
    options: [
      { text: 'Delegate tasks randomly to available team members', feedback: 'Random delegation can cause confusion; match tasks to skills.' },
      { text: 'Assign tasks based on individual strengths and current workload', feedback: 'Great choice! This ensures efficiency and better outcomes.' },
      { text: 'Avoid delegating and do everything yourself', feedback: 'Doing everything yourself can lead to burnout; learn to trust your team.' },
    ],
  },
  {
    id: 19,
    title: 'Balancing Innovation and Stability',
    description: 'Find the right balance between trying new things and maintaining what works.',
    image: 'https://images.unsplash.com/photo-1600566752066-3f73e123b1b0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGlubm92YXRpb258ZW58MHx8fHwxNjk0MTA0MTUy&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your team is pushing for new methods, but the current process works well. How do you decide?',
    options: [
      { text: 'Stick strictly to what works without changes', feedback: 'This can prevent growth; innovation is key to staying competitive.' },
      { text: 'Evaluate the pros and cons and test new approaches carefully', feedback: 'Balanced evaluation helps manage risks while encouraging innovation.' },
      { text: 'Implement all new ideas immediately', feedback: 'Rushed changes can lead to unforeseen issues; careful planning is better.' },
    ],
  },
  {
    id: 20,
    title: 'Building a Positive Workplace Culture',
    description: 'Cultivate a work environment that encourages positivity and engagement.',
    image: 'https://images.unsplash.com/photo-1521143670278-bd7c15c66e38?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHdvcmtwbGFjZXxlbnwwfHx8fDE2OTQxMDQxNTI&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Team morale is low. What can you do to improve the workplace culture?',
    options: [
      { text: 'Organize team-building events and recognize achievements', feedback: 'This helps boost morale and create a sense of community.' },
      { text: 'Implement stricter rules and expectations', feedback: 'Stricter rules can create pressure; a supportive approach is often more effective.' },
      { text: 'Ignore the issue, as it’s not your responsibility', feedback: 'Leadership involvement is crucial in shaping a positive culture.' },
    ],
  },
  {
    id: 21,
    title: 'Responding to Cybersecurity Threats',
    description: 'Handle potential security threats in a tech-driven workplace.',
    image: 'https://images.unsplash.com/photo-1581093448791-356e48b16050?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGN5YmVyc2VjdXJpdHl8ZW58MHx8fHwxNjk0MTA0MTUy&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your company faces a potential cybersecurity threat. How do you respond?',
    options: [
      { text: 'Ignore it until something happens', feedback: 'Ignoring security threats can lead to severe consequences; proactive measures are key.' },
      { text: 'Immediately alert the IT department and follow protocols', feedback: 'Excellent response! Timely action can mitigate damage.' },
      { text: 'Try to fix it yourself without informing others', feedback: 'Involving experts is critical; don’t handle security threats alone.' },
    ],
  },
  {
    id: 22,
    title: 'Handling Workplace Harassment Reports',
    description: 'Learn how to handle reports of harassment in a professional setting.',
    image: 'https://images.unsplash.com/photo-1533750349088-cd871a92f312?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGhhcmFzc21lbnR8ZW58MHx8fHwxNjk0MTA0MTUy&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'An employee reports harassment by a colleague. What steps do you take?',
    options: [
      { text: 'Brush it off as a misunderstanding', feedback: 'Ignoring reports can lead to a toxic work environment; take all reports seriously.' },
      { text: 'Follow your company’s harassment policy and investigate thoroughly', feedback: 'Correct! Proper investigation is essential to maintaining a safe workplace.' },
      { text: 'Publicly confront the accused without investigation', feedback: 'Avoid public confrontations; handle the situation with confidentiality and professionalism.' },
    ],
  },
  {
    id: 23,
    title: 'Conducting a Successful Job Interview',
    description: 'Refine your skills in conducting job interviews to select the best candidates.',
    image: 'https://images.unsplash.com/photo-1554774853-d50f6cf4bb3c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGludGVydmlld3xlbnwwfHx8fDE2OTQxMDQxNTI&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You’re interviewing candidates for an important role. How do you ensure the best fit?',
    options: [
      { text: 'Focus solely on technical skills and qualifications', feedback: 'Skills are important, but cultural fit and interpersonal skills also matter.' },
      { text: 'Ask situational questions to assess problem-solving and cultural fit', feedback: 'Good approach! This provides a deeper understanding of the candidate.' },
      { text: 'Hire the first qualified candidate to save time', feedback: 'Rushing the process can lead to poor hires; thorough evaluation is necessary.' },
    ],
  },
  {
    id: 24,
    title: 'Managing Time Zone Differences in a Global Team',
    description: 'Effectively manage a team that operates across different time zones.',
    image: 'https://images.unsplash.com/photo-1521790797524-b2497295b8e0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHRpbWV6b25lfGVufDB8fHx8MTY5NDEwNDE1Mg&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your team is spread across multiple time zones, making scheduling difficult. What do you do?',
    options: [
      { text: 'Schedule meetings at inconvenient times for most members', feedback: 'This approach can lead to frustration; balance is important.' },
      { text: 'Use asynchronous communication and flexible work hours', feedback: 'Excellent! This respects time zone differences and maintains productivity.' },
      { text: 'Ignore time zones and expect everyone to adjust', feedback: 'Unreasonable expectations can lead to disengagement; consider everyone’s needs.' },
    ],
  },
  {
    id: 25,
    title: 'Navigating Salary Negotiations with Employees',
    description: 'Handle salary negotiations effectively to retain talent and maintain fairness.',
    image: 'https://images.unsplash.com/photo-1573164574557-e0f9a1c6a85a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fG5lZ290aWF0aW9ufGVufDB8fHx8MTY5NDEwNDE1Mg&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'An employee asks for a salary increase citing market rates. How do you respond?',
    options: [
      { text: 'Reject the request without discussion', feedback: 'Dismissive responses can lead to disengagement and turnover.' },
      { text: 'Review market data, discuss performance, and negotiate a fair outcome', feedback: 'This approach respects the employee and maintains equity.' },
      { text: 'Agree immediately to avoid conflict', feedback: 'Agreeing without analysis can set unrealistic expectations for others.' },
    ],
  },
  {
    id: 26,
    title: 'Handling Employee Resignations',
    description: 'Learn how to professionally handle an employee resignation.',
    image: 'https://images.unsplash.com/photo-1551836022-d5d88e9218df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHJlc2lnbmF0aW9ufGVufDB8fHx8MTY5NDEwNDE2MA&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'An employee hands in their resignation unexpectedly. How do you respond?',
    options: [
      { text: 'Ask them to leave immediately', feedback: 'This response can create a negative experience; it’s better to discuss the reasons.' },
      { text: 'Hold an exit interview to understand their decision and gather feedback', feedback: 'Great approach! This can provide valuable insights for improvement.' },
      { text: 'Offer a counteroffer without understanding their reasons', feedback: 'It’s important to understand why they’re leaving before making a counteroffer.' },
    ],
  },
  {
    id: 27,
    title: 'Dealing with Employee Absenteeism',
    description: 'Address recurring absenteeism in a way that encourages accountability.',
    image: 'https://images.unsplash.com/photo-1590608897129-79d13fcd6dce?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGJvdHRvbXxlbnwwfHx8fDE2OTQxMDQxNjI&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'An employee has been frequently absent without clear reasons. How do you handle it?',
    options: [
      { text: 'Ignore it and hope it improves', feedback: 'Ignoring the issue can lead to more absenteeism; address it promptly.' },
      { text: 'Have a private discussion to understand the reasons and find solutions', feedback: 'Great choice! Understanding the cause can help in finding the right solution.' },
      { text: 'Publicly reprimand them in front of the team', feedback: 'Public reprimands can damage morale; handle it privately and respectfully.' },
    ],
  },
  {
    id: 28,
    title: 'Introducing New Technology to Your Team',
    description: 'Effectively introduce new tools or technology to improve team productivity.',
    image: 'https://images.unsplash.com/photo-1596495577886-d920f4557d1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHRlY2hub2xvZ3l8ZW58MHx8fHwxNjk0MTA0MTY0&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You want to implement a new software tool, but the team is resistant. What’s your approach?',
    options: [
      { text: 'Force the change without consulting the team', feedback: 'Forcing changes can lead to resentment; include the team in the decision-making process.' },
      { text: 'Demonstrate the benefits of the new tool and provide adequate training', feedback: 'Good choice! This helps build buy-in and ensures effective use.' },
      { text: 'Abandon the idea to avoid conflict', feedback: 'Avoiding change can hinder progress; it’s better to address concerns and find common ground.' },
    ],
  },
  {
    id: 29,
    title: 'Managing a Cross-Functional Team',
    description: 'Learn to lead a team with diverse roles and skill sets.',
    image: 'https://images.unsplash.com/photo-1595152772835-219674b2a8a6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGNyb3NzJTIwZnVuY3Rpb25hbCUyMHRlYW18ZW58MHx8fHwxNjk0MTA0MTY2&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your cross-functional team is struggling to collaborate effectively. What do you do?',
    options: [
      { text: 'Assign roles clearly and set common goals', feedback: 'Excellent! Clear roles and shared goals improve collaboration.' },
      { text: 'Let each function work independently without coordination', feedback: 'Lack of coordination can lead to misaligned efforts; foster collaboration.' },
      { text: 'Micromanage to ensure everyone is on the same page', feedback: 'Micromanaging can demotivate; trust your team to manage their responsibilities.' },
    ],
  },
  {
    id: 30,
    title: 'Giving a Presentation to Senior Leadership',
    description: 'Prepare for a high-stakes presentation to senior leaders.',
    image: 'https://images.unsplash.com/photo-1573497491208-6b1acb260507?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fZ2l2aW5nJTIwcHJlc2VudGF0aW9ufGVufDB8fHx8MTY5NDEwNDE3MA&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'You have a presentation to give to senior leadership. How do you prepare?',
    options: [
      { text: 'Rehearse thoroughly and prepare for possible questions', feedback: 'Good choice! Preparation builds confidence and ensures you can handle queries.' },
      { text: 'Go in without preparation to appear more natural', feedback: 'Lack of preparation can lead to missed points; practice is key.' },
      { text: 'Overload the presentation with data to impress', feedback: 'Data is important, but clarity and concise messaging are essential.' },
    ],
  },
  {
    id: 31,
    title: 'Handling Office Conflicts as a New Manager',
    description: 'Learn how to manage conflicts effectively as a new leader.',
    image: 'https://images.unsplash.com/photo-1600880292062-3d6bdc8911c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDh8fG9mZmljZSUyMGNvbmZsaWN0c3xlbnwwfHx8fDE2OTQxMDQxODg&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'As a new manager, you notice tensions between two team members. What’s your approach?',
    options: [
      { text: 'Ignore the conflict and focus on your own work', feedback: 'Ignoring conflict can worsen the situation; address it promptly.' },
      { text: 'Have a private meeting with each member to understand the issues', feedback: 'Great approach! Listening to both sides helps you mediate effectively.' },
      { text: 'Reprimand them publicly to set an example', feedback: 'Public reprimands can damage relationships; handle conflicts privately and professionally.' },
    ],
  },
  {
    id: 32,
    title: 'Building Resilience in Your Team',
    description: 'Strengthen your team’s ability to bounce back from setbacks.',
    image: 'https://images.unsplash.com/photo-1573164712491-1b9b04e0e10d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHJlc2lsaWVuY2V8ZW58MHx8fHwxNjk0MTA0MTg4&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your team is struggling with a recent project failure. How do you help them recover?',
    options: [
      { text: 'Ignore the failure and move on', feedback: 'Acknowledging failures and learning from them is crucial for growth.' },
      { text: 'Encourage a team discussion on what went wrong and how to improve', feedback: 'Excellent! Reflecting on mistakes helps build resilience and prevents future issues.' },
      { text: 'Punish those responsible for the failure', feedback: 'Punishing individuals can create fear; focus on learning and growth instead.' },
    ],
  },
  {
    id: 33,
    title: 'Managing Remote Employee Engagement',
    description: 'Keep remote employees engaged and connected with the team.',
    image: 'https://images.unsplash.com/photo-1603791440384-56cd371ee9c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fcmVtb3RlJTIwd29ya2VyJTIwZW5nYWdlbWVudHxlbnwwfHx8fDE2OTQxMDQxOTI&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your remote employees are feeling disconnected from the team. How do you engage them?',
    options: [
      { text: 'Hold regular virtual meetings and encourage casual check-ins', feedback: 'Great choice! Regular communication helps maintain connection and morale.' },
      { text: 'Focus only on work-related communication to save time', feedback: 'Work-related communication is important, but casual interactions also help bond the team.' },
      { text: 'Send out engagement surveys occasionally', feedback: 'Surveys can be useful, but consistent engagement efforts are more impactful.' },
    ],
  },
  {
    id: 34,
    title: 'Navigating Budget Cuts',
    description: 'Manage effectively when faced with budget reductions.',
    image: 'https://images.unsplash.com/photo-1573497491208-6b1acb260507?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fYnVkZ2V0fGVufDB8fHx8MTY5NDEwNDE5NA&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Your department faces significant budget cuts. How do you manage the situation?',
    options: [
      { text: 'Cut all non-essential team activities immediately', feedback: 'Cutting costs is necessary, but maintaining morale is also important.' },
      { text: 'Review expenditures carefully and prioritize critical areas', feedback: 'Excellent! Thoughtful adjustments help minimize the impact of budget cuts.' },
      { text: 'Request additional funds without justification', feedback: 'Requests for additional funds should be backed by strong reasoning and data.' },
    ],
  },
  {
    id: 35,
    title: 'Navigating a Crisis in Team Morale',
    description: 'Address and resolve low morale in your team.',
    image: 'https://images.unsplash.com/photo-1521143670278-bd7c15c66e38?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fG1vcmFsZXxlbnwwfHx8fDE2OTQxMDQxOTY&ixlib=rb-1.2.1&q=80&w=400',
    interactionDescription: 'Team morale is at an all-time low. How do you plan to improve it?',
    options: [
      { text: 'Implement new initiatives without consulting the team', feedback: 'Including the team in decision-making helps ensure initiatives address real concerns.' },
      { text: 'Gather feedback from the team and implement changes based on their suggestions', feedback: 'Great approach! Including your team fosters a sense of ownership and engagement.' },
      { text: 'Blame the team for their lack of enthusiasm', feedback: 'Blame fosters negativity; focus on understanding the root causes and addressing them.' },
    ],
  },
];
