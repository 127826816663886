<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-container">
      <h2 class="modal-title">{{ type === 'login' ? 'Login' : 'Sign Up' }}</h2>
      <form @submit.prevent="handleAuth" class="auth-form">
        <input
          v-if="type === 'signup'"
          v-model="username"
          type="text"
          placeholder="Enter your username"
          required
          class="input-field"
        />
        <input
          v-model="email"
          type="email"
          placeholder="Enter your email"
          required
          class="input-field"
        />
        <input
          v-model="password"
          type="password"
          placeholder="Enter your password"
          @input="checkPasswordCriteria"
          required
          class="input-field"
        />

        <!-- Password criteria checklist -->
        <ul v-if="type === 'signup'" class="password-criteria">
          <li :class="{ met: criteria.length }">
            <span v-if="criteria.length">✔</span>
            <span v-else>✖</span> At least 8 characters
          </li>
          <li :class="{ met: criteria.uppercase }">
            <span v-if="criteria.uppercase">✔</span>
            <span v-else>✖</span> At least one uppercase letter
          </li>
          <li :class="{ met: criteria.lowercase }">
            <span v-if="criteria.lowercase">✔</span>
            <span v-else>✖</span> At least one lowercase letter
          </li>
          <li :class="{ met: criteria.number }">
            <span v-if="criteria.number">✔</span>
            <span v-else>✖</span> At least one number
          </li>
          <li :class="{ met: criteria.special }">
            <span v-if="criteria.special">✔</span>
            <span v-else>✖</span> At least one special character
          </li>
        </ul>

        <button type="submit" class="auth-button">{{ type === 'login' ? 'Login' : 'Sign Up' }}</button>
      </form>
      <p class="toggle-text">
        {{ type === 'login' ? "Don't have an account?" : 'Already registered?' }}
        <span @click="toggleType" class="toggle-link">
          {{ type === 'login' ? 'Sign Up' : 'Login' }}
        </span>
      </p>
      <button @click="close" class="close-button">×</button>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import { supabase } from '../supabase';

export default {
  props: ['type'],
  emits: ['update:type', 'close'],
  data() {
    return {
      username: '',
      email: '',
      password: '',
      criteria: {
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
      },
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    // Check password criteria as the user types
    checkPasswordCriteria() {
      this.criteria.length = this.password.length >= 8;
      this.criteria.uppercase = /[A-Z]/.test(this.password);
      this.criteria.lowercase = /[a-z]/.test(this.password);
      this.criteria.number = /\d/.test(this.password);
      this.criteria.special = /[^A-Za-z0-9]/.test(this.password); // Matches any special character
    },
    async handleAuth() {
      try {
        if (this.type === 'signup') {
          // Validate all password criteria
          if (!Object.values(this.criteria).every((c) => c)) {
            this.toast.error('Please ensure your password meets all criteria.');
            return;
          }
          const { error } = await supabase.auth.signUp({
            email: this.email,
            password: this.password,
            options: {
              data: {
                username: this.username,
              },
            },
          });
          if (error) throw error;
          this.toast.success('Sign up successful! Please check your email to confirm.');
          this.$emit('close');
        } else {
          const { error } = await supabase.auth.signInWithPassword({
            email: this.email,
            password: this.password,
          });
          if (error) throw error;
          this.toast.success('Login successful!');
          this.$emit('close');
        }
      } catch (error) {
        this.toast.error(`Error: ${error.message}`);
      }
    },
    close() {
      this.$emit('close');
    },
    toggleType() {
      this.$emit('update:type', this.type === 'login' ? 'signup' : 'login');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal-container {
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  border: 4px solid #000;
  box-shadow: 10px 10px 0px #000;
  max-width: 400px;
  width: 90%;
  position: relative;
  text-align: center;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  background: #fce86b;
  padding: 10px;
  border: 3px solid #000;
  box-shadow: 5px 5px 0px #000;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 2px solid #000;
  border-radius: 8px;
  box-shadow: 4px 4px 0px #000;
  transition: all 0.3s ease;
}

.input-field:focus {
  outline: none;
  border-color: #4ecdc4;
}

.auth-button {
  padding: 12px;
  background: linear-gradient(45deg, #4ecdc4, #fc8181);
  color: #ffffff;
  font-weight: bold;
  border: 2px solid #000;
  border-radius: 8px;
  box-shadow: 4px 4px 0px #000;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.auth-button:hover {
  transform: translate(-2px, -2px);
}

.password-criteria {
  text-align: left;
  margin-top: 10px;
  padding-left: 20px;
  font-size: 14px;
  color: #333;
}

.password-criteria li {
  list-style: none;
  margin-bottom: 5px;
}

.password-criteria .met {
  color: green;
  font-weight: bold;
}

.toggle-text {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.toggle-link {
  color: #4ecdc4;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff6b6b;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 2px 2px 0px #000;
  transition: background 0.3s;
}

.close-button:hover {
  background: #c53030;
}
</style>
