<!-- src/components/BlogListView.vue -->
<template>
    <div class="blog-list-container p-8 bg-white border-4 border-black rounded-lg shadow-lg mt-10 max-w-4xl mx-auto">
      <!-- Blog Header -->
      <div class="blog-header mb-8 text-center">
        <h1 class="text-4xl font-bold mb-4">Blog</h1>
        <p class="text-lg text-gray-600 mb-4">Explore our latest insights on careers and the future of work.</p>
      </div>
  
      <!-- Blog Post List -->
      <div class="blog-posts space-y-6">
        <div
          v-for="post in blogPosts"
          :key="post.id"
          class="blog-post bg-white p-4 border-4 border-black rounded-lg shadow-md"
        >
          <h2 class="text-2xl font-semibold mb-2">{{ post.title }}</h2>
          <img :src="post.image" alt="Blog Post Image" class="w-full h-48 object-cover rounded-lg mb-4" />
          <p class="text-gray-700">{{ post.excerpt }}</p>
          <router-link
            :to="{ name: 'BlogDetails', params: { id: post.id }}"
            class="text-blue-600 underline hover:text-blue-400"
          >
            Read More
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        blogPosts: [
          {
            id: 1,
            title: "The Top 20 Jobs of the Future",
            image: "future-jobs-banner.jpg",
            excerpt: "Discover the careers that will dominate the future job market...",
          },
          {
            id: 2,
            title: "How to Build a Career in Data Science",
            image: "data-science-career.jpg",
            excerpt: "Data science is one of the fastest-growing fields in tech. Here's how to get started...",
          },
          {
            id: 3,
            title: "Mastering Remote Work: Top Tips",
            image: "remote-work.jpg",
            excerpt: "Remote work is here to stay. Learn how to maximize productivity while working from home...",
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .blog-list-container {
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 6px 6px 0px #000;
  }
  
  .blog-header img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 6px 6px 0px #000;
  }
  
  .blog-post h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .blog-post p {
    color: #555;
  }
  
  .space-y-6 > * + * {
    margin-top: 1.5rem;
  }
  
  .shadow-md {
    box-shadow: 6px 6px 0px #000;
  }
  </style>
  