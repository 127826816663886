<template>
    <div class="success-container">
      <h1 class="text-4xl font-bold text-green-500">Thank you for your purchase!</h1>
      <p class="mt-4 text-lg">Your premium membership has been activated.</p>
      <router-link to="/dashboard" class="mt-6 text-blue-500">Go to Dashboard</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SuccessView',
  };
  </script>
  
  <style scoped>
  .success-container {
    padding: 40px;
    text-align: center;
  }
  </style>
  