<template>
    <div class="cancel-container">
      <h1 class="text-4xl font-bold text-red-500">Your payment was canceled</h1>
      <p class="mt-4 text-lg">Feel free to browse our other plans or try again later.</p>
      <router-link to="/become-member" class="mt-6 text-blue-500">Go Back to Membership Options</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CancelView',
  };
  </script>
  
  <style scoped>
  .cancel-container {
    padding: 40px;
    text-align: center;
  }
  </style>
  