<template>
  <div id="app" class="bg-background min-h-screen flex flex-col text-gray-900 font-sans">
    <HeaderView v-if="!isLandingPage" />
    <main class="flex-1 p-6 container mx-auto">
      <!-- Render router view only after confirming it's not the landing page -->
      <router-view v-if="routeLoaded"></router-view>
    </main>
    <FooterView v-if="!isLandingPage" />
  </div>
</template>

<script>
import HeaderView from './components/HeaderView.vue';
import FooterView from './components/FooterView.vue';
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    HeaderView,
    FooterView,
  },
  setup() {
    const route = useRoute();
    const isLandingPage = computed(() => route.name === 'LandingPage');
    const routeLoaded = ref(false);

    onMounted(() => {
      routeLoaded.value = true;  // This ensures that the router has loaded before rendering the view
    });

    return {
      isLandingPage,
      routeLoaded,
    };
  },
};
</script>

<style scoped>
/* Add any additional global styles here if needed */
</style>
